import React, { useState, useEffect } from 'react';
import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/Layout';

import { useCookies } from 'react-cookie';
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from '@accrosoft-ltd/vf-careers-site-theme/src/components/analytics';
import { useTracking } from 'react-tracking';

import SmartFeedFilters from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedFilters';
import SmartFeedList from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedList';
import SmartFeedNavigation from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedNavigation';
import SmartFeedJobAlerts from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedJobAlerts';

import { useFiltersContext } from '@accrosoft-ltd/vf-careers-site-theme/src/context/smartfeed/FiltersContext';

require('es6-promise').polyfill();

export default function VacanciesPage(props) {
  const {
    filtersState: { filters },
    onSelectionChange,
  } = useFiltersContext();

  let internalView = false;
  let iFrameView = false;
  let HideCareersLink = false;

  if (props.urlLocation && props.urlLocation.search) {
    let searchParams = new URLSearchParams(props.urlLocation.search);
    let entries = searchParams.entries();

    for (let pair of entries) {
      if (pair[0] === 'iframe') {
        iFrameView = pair[1];
      }
      if (pair[0] === 'HideCareersLink') {
        HideCareersLink = pair[1];
      }
      if (pair[0] === 'IsIntranet') {
        internalView = pair[1];
      }
    }
  }

  const [vacancyLoading, setVacancyLoading] = useState(true);

  const siteConfig = props.siteConfig;

  const companyName =
    siteConfig?.CompanyName || props?.smartfeedCompanyData?.CompanyName || '';

  const [cookies, setCookie] = useCookies(['ap-signals-user-id']);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    'ap-signals-session-id'
  );

  const { trackEvent } = useTracking(
    {
      page: 'VacanciesPage',
      location: props.location,
      apiKey: props.apiKey,
    },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies['ap-signals-user-id'],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  useEffect(() => {
    trackAnalyticsPageView(
      props.location,
      cookies['ap-signals-user-id'],
      sessionIDValue,
      siteConfig
    );
  }, []);

  const onNavChange = (value) => {
    var temp = {
      target: {
        name: 'postingtype',
        value,
      },
    };
    onSelectionChange(temp);
  };

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={props.apiKey}
      groupOrIdParam={props.groupOrIdParam}
      smartfeedCompanyData={props.smartfeedCompanyData}
      appGatewayURL={props.appGatewayURL}
      vacanciesRouteLoading={props.vacanciesRouteLoading}
      vacanciesRouteError={props.vacanciesRouteError}
      trackEvent={trackEvent}
      title={`${companyName} Careers`}
      description={`Careers at ${companyName}`}
      useGroupVacsLink={props.useGroupVacsLink}
    >
      {!props.vacanciesRouteLoading &&
      (siteConfig.siteConfig || props.smartfeedCompanyData) &&
      props.apiKey ? (
        <>
          {/* <div
            className="banner page"
            style={{
              backgroundImage: `
            linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
            url("/banner.png")`,
            }}
          ></div> */}
          <div className="container">
            <h1 className="text-left text-white vacancies-page">Careers</h1>
          </div>
          <div class="home-white-fullwidth">
            <div className="container py-3">
              <div className="row">
                <div className="col-12 mb-3">
                  <SmartFeedNavigation
                    active={filters?.postingtype || 'Careers'}
                    onNavChange={onNavChange}
                  />
                </div>
              </div>
              {filters?.postingtype !== 'Alerts' ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      {siteConfig.showAdvancedFilters && (
                        <SmartFeedFilters
                          siteConfig={siteConfig}
                          apiKey={siteConfig.apiKey || props.apiKey}
                          group={
                            siteConfig.group
                              ? siteConfig.group
                              : props.groupOrIdParam === 'id'
                              ? false
                              : props.groupOrIdParam === 'group'
                              ? true
                              : false
                          }
                          groupOrIdParam={props.groupOrIdParam}
                        />
                      )}
                    </div>
                    <div className="col-12">
                      <SmartFeedList
                        siteConfig={siteConfig}
                        apiKey={siteConfig.apiKey || props.apiKey}
                        group={
                          siteConfig.group
                            ? siteConfig.group
                            : props.groupOrIdParam === 'id'
                            ? false
                            : props.groupOrIdParam === 'group'
                            ? true
                            : false
                        }
                        smartfeedCompanyData={
                          siteConfig.smartfeedCompanyData ||
                          props.smartfeedCompanyData
                        }
                        trackEvent={props.trackEvent}
                        useGroupVacsLink={props.useGroupVacsLink}
                        vacancyLoading={vacancyLoading}
                        setVacancyLoading={setVacancyLoading}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-8">
                    <SmartFeedJobAlerts
                      siteConfig={siteConfig}
                      trackEvent={trackEvent}
                      apiKey={props.apiKey}
                      gdprKey={props.gdprKey}
                      group={props.group}
                      CompanyGroupName={props.CompanyGroupName}
                      VacancyPageBaseURL={props.VacancyPageBaseURL}
                      ReturnToSearchURL={props.ReturnToSearchURL}
                      OptInLink={props.OptInLink}
                      UnsubscribeLink={props.UnsubscribeLink}
                      location={siteConfig.showLocationJA}
                      region={siteConfig.showRegionJA}
                      category={siteConfig.showCategoryJA}
                      industry={siteConfig.showIndustryJA}
                      jobType={siteConfig.showJobTypeJA}
                      jobTime={siteConfig.showJobTimeJA}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="notfound">
          <section className="app-content">
            <div className="app-content-content">
              <div className="container">
                <div className="loading-panel">
                  <div className="loading-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span className="loading-text">
                    Please wait... If content is not shown soon then you may
                    have the wrong URL.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}
